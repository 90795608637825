@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Nunito'), local('Nunito-SemiBold'),
    url(./assets/fonts/Nunito-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Nunito Sans'), local('NunitoSans-Bold'),
    url(./assets/fonts/NunitoSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Nunito Sans'), local('NunitoSans-Regular'),
    url(./assets/fonts/NunitoSans-Regular.ttf) format('truetype');
}
